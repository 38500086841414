var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb--100"},[_c('v-col',{attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"blog blog-style--1 bg_color--1"},[_c('div',{staticClass:"text-center mb-30"},[_c('div',{staticClass:"service-cap",staticStyle:{"margin-top":"2%"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('div',{staticClass:"imgBlog"},[_c('img',{staticStyle:{"width":"130px"},attrs:{"src":require("@/assets/images/logo/logo.png")}})]),_c('br'),_c('br'),_c('h4',[_c('b',[_vm._v("Καύσιμα & Υπηρεσίες")])]),_c('br'),_c('br'),_c('div',{staticClass:"paragraphBlog"},[_c('p',[_vm._v(" H "),_c('i',[_c('b',[_vm._v("ελίν")])]),_vm._v(" σε επιβραβεύει για κάθε λίτρο καυσίμου και για κάθε προϊόν/υπηρεσία που επιλέγεις. ")])])])],1),[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","width":"auto "},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button_beaut",attrs:{"color":"#004e96","rounded":""}},'v-btn',attrs,false),on),[_vm._v("Δες περισσότερα")])]}}])},[[_c('v-card',[_c('center',[_c('h4',{staticStyle:{"padding-top":"25px"}},[_vm._v(" Καύσιμα & Υπηρεσίες ελίν ")])]),_c('v-img',{staticClass:"col-md-12 col-lg-12 col-sm-12 col-12 text-center",attrs:{"contain":true,"src":require("@/assets/images/blog/kausima_image2.jpg")}})],1)]],2)],1)],1)]],2)])])]),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"blog blog-style--1 bg_color--1"},[_c('div',{staticClass:"text-center mb-30"},[_c('div',{staticClass:"service-cap",staticStyle:{"margin-top":"2%"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('div',{staticClass:"imgBlog"},[_c('img',{staticStyle:{"width":"110px"},attrs:{"src":require("@/assets/images/blog/lip.png")}})]),_c('br'),_c('br'),_c('h4',[_c('b',[_vm._v("Λιπαντικά")])]),_c('br'),_c('br'),_c('div',{staticClass:"paragraphBlog"},[_c('p',[_vm._v(" Aγόρασε λιπαντικά "),_c('i',[_c('b',[_vm._v("ελίν")])]),_vm._v(" και επωφελήσου άμεσα με πόντους "),_c('b',[_c('i',[_vm._v("ελίν up")]),_vm._v(" επιβράβευση")]),_vm._v(" για κάθε ευρώ αγορών. ")])])])],1),[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","width":"auto "},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button_beaut",attrs:{"color":"#004e96","rounded":""}},'v-btn',attrs,false),on),[_vm._v("Δες περισσότερα")])]}}])},[[_c('v-card',[_c('center',[_c('h4',{staticStyle:{"padding-top":"25px"}},[_vm._v(" Καύσιμα & Υπηρεσίες ελίν ")])]),_c('v-img',{staticClass:"col-md-12 col-lg-12 col-sm-12 col-12 text-center",attrs:{"contain":true,"src":require("@/assets/images/blog/kausima_image2.jpg")}})],1)]],2)],1)],1)]],2)])])]),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"blog blog-style--1 bg_color--1"},[_c('div',{staticClass:"text-center mb-30"},[_c('div',{staticClass:"service-cap",staticStyle:{"margin-top":"2%"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('div',{staticClass:"imgBlog"},[_c('img',{staticStyle:{"width":"170px"},attrs:{"src":require("@/assets/images/blog/electriconn.png")}})]),_c('br'),_c('br'),_c('h4',[_c('b',[_vm._v("Ηλεκτρική Ενέργεια Φυσικό Αέριο")])]),_c('br'),_c('div',{staticClass:"paragraphBlog"},[_c('p',[_vm._v(" Tώρα και οι πελάτες της Ηλεκτρικής Ενέργειας αλλά και του Φυσικού Αερίου απολαμβάνουν προνόμια. ")])])])],1),[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","width":"auto "},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button_beaut",attrs:{"color":"#004e96","rounded":""}},'v-btn',attrs,false),on),[_vm._v("Δες περισσότερα")])]}}])},[[_c('v-card',[_c('center',[_c('h4',{staticStyle:{"padding-top":"25px"}},[_vm._v(" Ηλεκτρική Ενέργεια / Φυσικό Αέριο ")])]),_c('v-img',{staticClass:"col-md-12 col-lg-12 col-sm-12 col-12 text-center",attrs:{"contain":true,"src":require("@/assets/images/blog/Site_elin_gif.gif")}})],1)]],2)],1)],1)]],2)])])]),_c('v-col',{staticClass:"d-sm-none d-md-block",attrs:{"lg":"2","md":"2","sm":"12","cols":"12"}}),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"blog blog-style--1 bg_color--1"},[_c('div',{staticClass:"text-center mb-30"},[_c('div',{staticClass:"service-cap",staticStyle:{"margin-top":"2%"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('div',{staticClass:"imgBlog"},[_c('img',{staticClass:"mt--9",staticStyle:{"width":"170px"},attrs:{"src":require("@/assets/images/blog/fygame.png")}})]),_c('br'),_c('br'),_c('h4',[_c('b',[_vm._v("figame.gr")])]),_c('br'),_c('div',{staticClass:"paragraphBlog"},[_c('p',[_vm._v(" Κάθε καταναλωτής που αγοράζει αεροπορικά εισιτήρια κερδίζει πόντους "),_c('b',[_c('i',[_vm._v("ελίν up")]),_vm._v(" επιβράβευση")]),_vm._v(" . ")])])])],1),[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","width":"auto "},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button_beaut",attrs:{"color":"#004e96","rounded":""}},'v-btn',attrs,false),on),[_vm._v("Ξεκινήστε σήμερα")])]}}])},[[_c('v-card',[_c('center',[_c('h4',{staticStyle:{"padding-top":"25px"}},[_vm._v("figame.gr")])]),_c('v-img',{staticClass:"col-md-12 col-lg-12 col-sm-12 col-12 text-center",attrs:{"contain":true,"src":require("@/assets/images/blog/figamein.png")}})],1)]],2)],1)],1)]],2)])])]),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"blog blog-style--1 bg_color--1"},[_c('div',{staticClass:"text-center mb-30"},[_c('div',{staticClass:"service-cap",staticStyle:{"margin-top":"2%"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('div',{staticClass:"imgBlog"},[_c('img',{staticStyle:{"width":"111px"},attrs:{"src":require("@/assets/images/blog/nogifts.jpg")}})]),_c('br'),_c('br'),_c('h4',[_c('b',[_vm._v("Μοναδικά Προνόμια")])]),_c('br'),_c('div',{staticClass:"paragraphBlog"},[_c('p',[_vm._v(" Κέρδισε επιπλέον πόντους απο προωθητικές ενέργειες της "),_c('i',[_c('b',[_vm._v("ελίν")])]),_vm._v(". ")])])])],1),[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto","align":"center","justify":"center"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","width":"auto "},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.promos&&_vm.promos.length&&_vm.activePromoExists || _vm.promoWinnersExist || _vm.urlMore)?_c('v-btn',_vm._g(_vm._b({staticClass:"button_beaut d-flex",attrs:{"color":"#004e96","href":!!_vm.urlMore && _vm.urlMore,"rounded":""}},'v-btn',attrs,false),on),[_vm._v("Δες περισσότερα")]):_vm._e()]}}])},[[(_vm.activePromoExists)?_c('v-card',{staticStyle:{"max-width":"500px"}},[_c('v-toolbar',[_c('h4',[_vm._v(" Μοναδικά Προνόμια ")])]),_c('v-img',{staticClass:"pronomia col-md-12 col-lg-12 col-sm-12 col-12 text-center",attrs:{"contain":true,"src":require("@/assets/images/promos/web_500x800.jpg")}}),_c('v-row',{staticStyle:{"padding":"20px"},attrs:{"justify":"space-around"}},_vm._l((_vm.promos),function(promo,i){return _c('v-dialog',{key:i,attrs:{"transition":"dialog-top-transition","width":"auto "},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(promo.expires > _vm.today)?_c('v-btn',_vm._g(_vm._b({staticClass:"button_beaut",staticStyle:{"padding":"0 15%","margin-top":"15px"},attrs:{"color":"#004e96","rounded":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(promo.btnTitle))]):_vm._e()]}}],null,true)},[[_c('v-card',[_c('v-toolbar',[_c('h4',[_vm._v(_vm._s(promo.btnTitle))])]),_c('div',{staticStyle:{"padding":"25px"}},[_c('h3',[_vm._v(_vm._s(promo.title))]),_vm._l((promo.terms),function(term,ti){return _c('p',{key:ti},[_vm._v(_vm._s(term))])}),_c('h4',[_vm._v("Πρατήρια που συμμετέχουν")]),_c('ul',_vm._l((promo.stores),function(store,si){return _c('li',{key:si},[_vm._v(_vm._s(store))])}),0)],2)],1)]],2)}),1)],1):_vm._e(),(_vm.promoWinnersExist)?_c('v-card',{staticStyle:{"max-width":"500px"}},[_c('v-toolbar',[_c('h4',[_vm._v(" Καύσιμα του καλοκαιριού ")])]),_c('v-img',{staticClass:"pronomia col-md-12 col-lg-12 col-sm-12 col-12 text-center",attrs:{"contain":true,"src":require("@/assets/images/promos/newParticipant_ATTIKH_PUSH_1440X720.jpg")}}),_c('v-row',{staticStyle:{"padding":"20px"},attrs:{"justify":"space-around"}},_vm._l((_vm.promos),function(promo,i){return _c('div',{key:i,attrs:{"transition":"dialog-top-transition","width":"auto "}},[(promo.results&&promo.results.expires > _vm.today)?_c('v-card',[_c('v-toolbar',[_c('h4',[_vm._v(_vm._s(promo.results.description))])]),_c('div',{staticStyle:{"padding":"25px"}},_vm._l((promo.results.winners),function(winner,ti){return _c('p',{key:ti},[_vm._v(_vm._s(winner))])}),0)],1):_vm._e()],1)}),0)],1):_vm._e()]],2)],1)],1)]],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
    >
    <div class="single-service  mt--60">
          <div class="service">
            <div class="content">
                <div class="single-features mb-70" style="display: flex;">
                <img src="@/assets/images/brand/dapp.png" style="width:70px;height:70px;" class="mr--10">
                <div class="features-caption">
                  <h3 class="heading-title">Κατέβασε το <b><i>ελίν up</i></b> επιβράβευση</h3>
                    <p>
                      H εφαρμογή είναι διαθέσιμη δωρεάν στο Apple Store και στο Google Play.
                    </p>
                </div>
              </div>
                
            </div>
          </div>
      </div>
    </v-col>
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
    >
     <div class="single-service  mt--60">
          <div class="service">
            <div class="content">
                <div class="single-features mb-70" style="display: flex;">
                <img src="@/assets/images/brand/fuel.png" style="width:70px;height:70px;" class="mr--10">
                <div class="features-caption">
                  <h3 class="heading-title">Αναζήτησε πρατήριο</h3>
                    <p>
                      Eπίλεξε από τον χάρτη το κοντινότερο <a href="/contact">πρατήριο <b><i>ελίν</i></b></a>.
                    </p>
                </div>
              </div>
                
            </div>
          </div>
      </div>
    </v-col>
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
    >

    <div class="single-service  mt--60">
          <div class="service">
            <div class="content">
                <div class="single-features mb-70" style="display: flex;">
                <img src="@/assets/images/brand/paym.png" style="width:70px;height:70px;" class="mr--10">
                <div class="features-caption">
                  <h3 class="heading-title">Πλήρωσε με το κινητό</h3>
                    <p>
                      Αγοράζοντας καύσιμα και υπηρεσίες από το κινητό κερδίζεις <b>διπλάσιους</b> πόντους.
                    </p>
                </div>
              </div>
                
            </div>
          </div>
      </div>
    </v-col>
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
    >
    <div class="single-service  mt--60">
          <div class="service">
            <div class="content">
                <div class="single-features mb-70" style="display: flex;">
                <img src="@/assets/images/brand/win.png" style="width:70px;height:70px;" class="mr--10">
                <div class="features-caption">
                   <h3 class="heading-title">Γέμισε δώρα</h3>
                    <p>
                      Απόκτησε γρήγορα τα δώρα από τον ψηφιακό <a href="/categories">κατάλογο</a>.
                    </p>
                </div>
              </div>
                
            </div>
          </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
                icon: "fuel.png",
                image: "fuel",
                title: "Επίλεξε πρατήριο ελίν",
                desc: `Eπίλεξε ένα πρατήριο ελίν που συμμετέχει στο πρόγραμμα.`,
          },
          {
              icon: "catalog.png",
              image: "catalog",
              title: "Αγόρασε καύσιμα",
              desc: `Προμηθεύσου καύσιμα ή υπηρεσίες ελίν από το πρατήριο.`,
          },
          {
              icon: "scan.png",
              image: "scan",
              title: "Σκάναρε την κάρτα σου",
              desc: `Μετά την αγορά δώσε την κάρτα σου για να περαστούν οι πόντοι.`,
          },
          {
              icon: "gift1.png",
              image: "gift1",
              title: "Εξαργύρωσε τους πόντους",
              desc: `Εξαργύρωσε τους πόντους από τον κατάλογο σε δώρα και δωροεπιταγές .`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
        },
        getImgUrl(image) {
            var images = require.context('@/assets/images/brand/', false, /\.png$/)
            return images('./' + image + ".png")
        },
    },
  };
</script>
<style scoped>
  a {
    color: #104788;
    text-decoration: underline;
  }
  .section-title p a {
     color: #104788;
    text-decoration: underline;
  }
  .v-application a {
    color: #104788;
  }
</style>

<template>
  <v-row class="mb--100">
    <v-col v-for="(tab, index) in redemptionTabs" :key="index" :lg="colsPerTab" :md="colsPerTab" sm="6" cols="12">
      <div class="blog blog-style--1 bg_color--1">
        <div class="text-center mb-30">
          <div class="service-cap d-flex flex-column" style="margin-top: 2%;">
            <v-row align="center" justify="center">
              <v-col cols="auto" align="center" justify="center">
                <div class="imgBlog">
                  <img
                    class="mt--9"
                    :src="tab.img"
                    :style="{width:'120px', borderRadius: tab.img.includes('epic23')?'50%':undefined}"
                  />
                </div>
                <div>
                  <h4 class="more-header more-content pt-6" v-html="tab.title"></h4>
                  <div class="paragraphBlog more-content">
                    <p  v-html="tab.description">
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <template>
              <v-row justify="space-around">
                <v-col cols="auto" align="center" justify="center" class="redemption-more-info">
                  <v-btn
                        class="button_beaut"
                        color="#004e96"
                        @click="loadActiveCategories(tab.categories)"
                        rounded
                        >Δες περισσότερα</v-btn
                      >
                </v-col>
              </v-row>
            </template>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
import Modal from "@/components/modal/Modal";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "PointRedemption",
  components: {
    Modal,
  },
  data() {
    return {
      // today: new Date(),
      
    };
  },
    computed: {
      ...mapGetters (["activeCategories", "categories"]),

      categoryIds(){
        return (this.categories||[]).map(e =>e.gift_category_id)
      },
      redemptionTabs() {
        let tabs=
        [
          {
            img: require('@/assets/images/blog/icons3.png'),
            title: `Εξαργύρωση σε <b>δώρα</b>`,
            description: `Εξαργύρωσε τους πόντους σου σε μοναδικά <b>δώρα</b> & απόλαυσε την καθημερινότητά σου`,
            categories: [1,2,3,4,5,6,7]
          },
          {
            img: require('@/assets/images/blog/icons1.png'),
            title: `Εξαργύρωση σε <b>δωροεπιταγές ελίν</b>`,
            description: `Εξαργύρωσε τους πόντους σου σε <b>δωροεπιταγές <i>ελίν</i></b>, στα πρατήρια <b><i>ελίν</i></b> που συμμετέχουν στο <b><i>up</i> επιβράβευση</b>.`,
            categories: [31]
          },
          {
            img: require('@/assets/images/blog/epic23.jpg'),
            title: `Εξαργύρωση με <b>συμμετοχή σε κληρώσεις</b>`,
            description: `Εξαργύρωσε πόντους <b><i>ελίν up</i></b> και λάβε μέρος στις μεγάλες <b>κληρώσεις</b>, διεκδικώντας μοναδικά δώρα`,
            categories: [371]
          },
          {
            img: require('@/assets/images/blog/icons4.png'),
            title: `<b>Κατάλογος δώρων</b>`,
            description: `Απόλαυσε έναν κόσμο προνομίων στον κατάλογο δώρων <b><i>ελίν up</i> επιβράβευση</b>`,
            categories: [...this.categoryIds]
          },
        ]
        return tabs
        // return tabs.filter(e => e.categories.length)
      },
      colsPerTab(){
        return 12/this.redemptionTabs.length < 3 ? 3 : 12/this.redemptionTabs.length
      }
    },
    methods: {
      ...mapMutations (["setActiveCategories"]),
      ...mapActions(["getGifts","getCategories"]),

      async loadActiveCategories(ids=[]){
        this.setActiveCategories([...ids])
        this.$router.push({ path: 'categories', query: { category: ids||[1], page:  1}})
      },
    },
    
    async  created () {
            await this.getCategories();
        },
    
};
</script>
<style lang="scss" scoped>
.overflow-hidden {
  overflow: hidden;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1234px;
}
.button_beaut:hover {
  background-color: white !important;
  color: #104788 !important;
  border: 1px solid #104788 !important;
}
.paragraphBlog {
  min-height: 150px;
}

.imgBlog {
  min-height: 100px;
}
// .v-image.pronomia .v-responsive__sizer{
//   padding-bottom: 250px !important
// }
.redemption-more-info {
  position: absolute;
  bottom: 40px;
}

.more-header{
  min-height: 100px;
}
.more-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}
</style>

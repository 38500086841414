<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" src="@/assets/images/logo/logo.png" />
        </Header>
        <!-- End Header Area -->
        <!-- Start Slider Area -->
        <div class="slider-wrapper">
            <SliderOne></SliderOne>
        </div>
        <!-- End Slider Area -->
        <!-- Start Service Area -->
        <div class="service-area ptb--80  bg_image">
                <v-row>
                    <v-col lg="4" md="12" sm="12" cols="12" class="mr--70">
                        <div class="section-title text-left ml-10 mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <img slot="thum-img"
                                 class="w-80"
                                 src="@/assets/images/brand/card.png"
                                 alt="About Images" />
                        </div>
                    </v-col>

                    <v-col lg="5" md="12" sm="12" cols="12" class="ml--10">
                        <h2 class="head-title">Κάρτα μέλους <br><b style="color: #104788;"><i>ελίν up</i></b> <b style="color: #104788;">επιβράβευση</b></h2>
                         <ServiceCardElin />

                    </v-col>
                    <v-col lg="2" md="2" sm="2" cols="12" class="mt_md--50">
                    </v-col>
                </v-row>
        </div>
        <!-- End Service Area -->

        <!-- Start Service Area -->
        <div class="service-area ptb--80  bg_image">
                <v-row>
                    <v-col lg="4" md="12" sm="12" cols="12" class="mr--70">
                        <div class="section-title text-left ml-10 mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <img slot="thum-img"
                                 class="w-80"
                                 src="@/assets/images/brand/mobile_payments.jpg"
                                 alt="About Images" />
                        </div>
                    </v-col>
                    <v-col lg="5" md="12" sm="12" cols="12" class="ml--10">
                        <h2 class="head-title">Αγόρασε «ανέπαφα» <br>κέρδισε διπλά</h2>
                        <ServiceAppElin />
                    </v-col>
                    <v-col lg="2" md="2" sm="2" cols="12" class="mt_md--50">
                    </v-col>
                </v-row>
        </div>
        <!-- End Service Area -->
        <!-- Start Blog Area  -->
        <div id="getPoints" class="rn-blog-area rn-section-gapTop bg_light_blue">
            <v-container>
                <v-row align="end" class="mb--20">
                    <v-col lg="12" md="12" sm="12" cols="12">
                        <div class="section-title text-center">
                            <h2 class="head-title">
                                Συλλογή πόντων <br />
                                <b><i>ελίν up </i>επιβράβευση</b>
                            </h2>
                        </div>
                    </v-col>
                </v-row>
                <GainPoints />
            </v-container>
        </div>
        
        <div id="pointRedemption" class="rn-blog-area rn-section-gapTop bg_light_blue">
            <v-container>
                <v-row align="end" class="mb--20">
                    <v-col lg="12" md="12" sm="12" cols="12">
                        <div class="section-title text-center">
                            <h2 class="head-title">
                                <b>Εξαργύρωση</b> πόντων <br />
                                <b><i>ελίν up </i>επιβράβευση</b>
                            </h2>
                        </div>
                    </v-col>
                </v-row>
                <PointRedemption />
            </v-container>
        </div>
        <!-- End Blog Area  -->
        <!-- Start Service Area -->
        <div class="service-area ptb--80  bg_image bg_gradient_blue">
            <v-container>
                <v-row>

                    <v-col lg="6" md="6" sm="12" cols="12" >
                        <h2 class="head-title" style="color: white">Kατέβασε την εφαρμογή <i>ελίν</i> up επιβράβευση</h2>
                        <p style="color: white">
                            Χρησιμοποίησε το <b style="color: #ffffff; "><i>ελίν up</i></b> <b style="color: #ffffff;">επιβράβευση</b>
                            και απόλαυσε mobile συναλλαγές στα πρατήρια <b style="color: #ffffff; "><i>ελίν</i></b> .
                        </p>
                        
                         <v-row align="end" class="mb--50 mt--50">
                            <v-col class="mb--20" lg="4" md="4" sm="12" cols="12">
                                <a href="https://apps.apple.com/gr/app/elin-up-rewards/id1539457348?l=el" target="blank" class="app-btn1"><img src="@/assets/images/brand/app_btn1.png" alt=""></a>
                            </v-col>
                            <v-col class="mb--20" lg="4" md="4" sm="12" cols="12">
                                <a href="https://play.google.com/store/apps/details?id=com.roadcube.elinuprewards&amp;hl=en&amp;gl=US" target="blank" class="app-btn2"><img src="@/assets/images/brand/app_btn2.png" alt=""></a>

                            </v-col>
                            <v-col lg="4" md="4" sm="12" cols="12">

                            </v-col>
                        </v-row>

                    </v-col>
                    <v-col lg="1" md="1" sm="12" cols="12" class="mt_md--50">
                        
                    </v-col>
                    <v-col lg="5" md="5" sm="12" cols="12" class="mt_md--50">
                        <div class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <img slot="thum-img"
                                width="770px"
                                 class="w-250"
                                 src="@/assets/images/brand/new_elin_download.png"
                                 alt="About Images" />
                        </div>
                    </v-col>
                    
                </v-row>
            </v-container>
        </div>
        <!-- Start Service Area -->
        <div class="service-area ptb--5  bg_image"  v-if="this.$store.state.user === null">
                <v-row >
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <div class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <img slot="thum-img"
                                 class="w-80"
                                 style="max-width: 45%;"
                                 src="@/assets/images/brand/aerostato_transparent.png"
                                 alt="About Images" />
                        </div>
                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12" class="pt--100">
                        <h2><b>Ξεκίνα εύκολα την εγγραφή σου</b></h2>
                    </v-col>
                    <v-col lg="3" md="3" sm="12" cols="12" class="pt--100">
                        <Register notHeader=true title="Εγγραφή" /> 
                    </v-col>
                </v-row>
        </div>
        <!-- End Service Area -->
        
        <!-- End Service Area -->
        <!-- Start Brand Area  -->
        <!-- Start Brand Area -->
        <div class="rn-brand-area brand-separation">
            <v-container>
                <v-row>
                </v-row>
            </v-container>
        </div>
        <!-- End Brand Area -->
        <!-- Start Footer Area  -->
        <Footer />
        <!-- End Footer Area  -->
    </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import SliderOne from "@/components/slider/SliderOne";
  import ServiceAppElin from "@/components/service/ServiceAppElin";
  import ServiceCardElin from "@/components/service/ServiceCardElin";
  import GainPoints from "@/components/blog/GainPoints";
  import PointRedemption from "@/components/blog/PointRedemption";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import Register from "@/views/Register";


  export default {
    components: {
      Register,
      Header,
      SliderOne,
      ServiceCardElin,
      ServiceAppElin,
      GainPoints,
      PointRedemption,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>
<style scoped>
.button_beautify {
    padding: 30px 43px !important;
    border-radius: 30px !important;
    background: #19b3e6 !important;
  }
</style>
